.footer_container {
    background-color: #1d1c1c7d;
    position: fixed;
    bottom: 0%;
    width: 100%;
    font-size: 1.5em;
    height: 20%;
    z-index: 4;
    align-items: center;
    display: flex;
    flex-direction: column;
}

@media (max-width: 400px) {
    .footer_container {
        bottom: 2%;
    }
}

.web_camera {
    position: fixed;
    object-fit: contain;
    object-position: 50% 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
}


.both_pose_svg {
    height: 60%;
    width: 100%;
    margin-top: 90px;
}

.phase_instruction {
    margin: 0;
    font-size: 18px;
    text-align: center;
    padding: 10px 10px 5px 10px;
}

.button_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.capture_image {
    margin: 0px 2% 1%;
    cursor: pointer;
    max-width: 140px;
    width: 90px;
}

.close_image {
    position: absolute;
    top: 95px;
    right: calc(100% - 80%);
    z-index: 25;
    cursor: pointer;
}

.close_svg {
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 768px) {
    .web_camera{
        object-fit: cover;
    }
    .close_image {
        right: calc(100% - 95%);
    }
}

@media screen and (max-width: 600px) {
    .capture_image{
        width: 50px;
        height: 30px;
    }
    .footer_container{
        height: 10%;
    }
    .web_camera{
        height: 90%;
        object-fit: cover;
    }
    .phase_instruction{
        font-size: 13px;
    }
    .both_pose_svg {
        position: absolute;
        bottom: 16%;
        left: 0px;
        margin-top: 0px;
        height: 50%;
    }
    .close_image {
        position: absolute;
        top: 95px;
        right: 15px;
    }
    .close_svg {
        width: 30px;
        height: 30px;
    }
}